<template>
  <main>
    <content-page-header>
      <div class="hdg-tab">
        <common-page-title title="新規会員登録" />
        <tab-nation now-page="5" />
      </div>
    </content-page-header>
    <div class="content-body">
      <panel-body>
        <div class="apply-register-panel">
          <div class="temp-registration">
            <section>
              <common-block-title
                title="THANK YOU"
                sub-title="ありがとうございました"
                class-list="en"
              />
              <p class="thanks-message">
                登録プロセスが完了しました。
                ジッセン!DXにご参加いただきありがとうございます。<br />
                下のボタンをクリックしてトップページに戻ってください。
              </p>
            </section>
            <div class="submit-area">
              <button
                class="submit-area-button submit-area-button--save"
                @click="$router.push('/')"
              >
                TOPページへ
              </button>
            </div>
          </div>
        </div>
      </panel-body>
    </div>
  </main>
</template>

<script>
import { defineComponent, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import TabNation from "./components/tabNation.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
export default defineComponent({
  name: "applyInputComplete",

  components: {
    contentPageHeader,
    CommonPageTitle,
    TabNation,
    CommonBlockTitle,
    PanelBody,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const viewState = reactive({});

    window.setTimeout(function () {
      router.push("/");
    }, 5000);
    window.scrollTo(0, 0);
    const isLogin = computed(() => store.state.user.isLogin);

    onMounted(async () => {
      if (isLogin.value) {
        router.push("/");
        return;
      }
    });
    watch(
      () => store.state.user.isLogin,
      async () => {
        if (isLogin.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      store,
      viewState,
    };
  },
});
</script>

<style lang="scss" scoped>
.hdg-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(lg) {
    flex-direction: column;
    align-items: flex-start;
  }
  @include mq(md) {
    display: block;
  }
}

.content-body {
  position: relative;
  margin-top: -221px;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;
  @include mq(md) {
    margin-top: -590px;
  }
  @include mq(sm) {
    margin-top: -100px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
.panel-body {
  margin-bottom: 50px;
  padding: 65px;
  background-color: $white;
  box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
  backdrop-filter: blur(10px);
  @include mq(sm) {
    padding: 60px 30px;
  }
}
.thanks-message {
  margin: 40px 0 65px;
  padding-bottom: 65px;
  border-bottom: 0.5px solid $black;
}
</style>