<template>
  <div class="tab-nation">
    <ul class="tab-nation-list">
      <li
        :class="
          i + 1 === Number(nowPage)
            ? 'is-active'
            : i + 1 < Number(nowPage)
            ? 'is-finish'
            : ''
        "
        v-for="(item, i) in 5"
        :key="i"
      >
        <span>0{{ i + 1 }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "TabNation",

  components: {},
  props: ["nowPage"],
  setup() {
    const state = reactive({});

    return {
      state,
    };
  },
});
</script>

<style lang="scss" scoped>
.tab-nation {
  @include mq(lg) {
    margin-top: 60px;
  }
  .tab-nation-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include mq(sm) {
      margin-right: -30px;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      margin-right: 20px;
      background: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(100px);
      @include mq(sm) {
        width: 60px;
        height: 60px;
        margin-right: 0px;
      }
      &:last-of-type {
        margin-right: 0;
      }
      &.is-active {
        background: #ffffff;
        box-shadow: 0px 20px 30px rgba(72, 159, 157, 0.25);
      }
      &.is-finish {
        background: #ffffff;
        box-shadow: none;
        span {
          color: $black;
        }
      }
      span {
        font-family: Roboto Condensed;
        font-weight: bold;
        font-size: 26px;
        color: $primary;
      }
    }
  }
}
</style>