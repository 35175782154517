<template>
  <div class="heading-2">
    <h2 class="hdg">{{ title }}</h2>
    <p v-if="subTitle" class="subHdg">{{ subTitle }}</p>
    <p v-if="count" class="count">（{{ count }}）</p>
  </div>
</template>

<script>
export default {
  props: ["title", "subTitle", "count"],
};
</script>

<style lang="scss" scoped>
.heading-2 {
  display: flex;
  align-items: center;
  @include mq(sm) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .hdg {
    position: relative;
    margin: 0;
    font-family: "Roboto Condensed", "Noto Sans JP", sans-serif;
    font-size: 40px;
    font-weight: 700;
    @include mq(md) {
      font-size: 30px;
    }
    @include mq(sm) {
      margin-top: 10px;
    }
    &::before {
      content: "";
      width: 10px;
      height: 62px;
      position: absolute;
      top: 50%;
      left: -65px;
      background-color: $primary;
      transform: translate(0, -50%);
      @include mq(sm) {
        height: 40px;
        left: -30px;
      }
    }
  }
  .subHdg {
    margin-left: 40px;
    font-size: 20px;
    color: $black-50;
    @include mq(md) {
      font-size: 18px;
    }
    @include mq(sm) {
      margin-left: 0;
    }
  }
  .count {
    margin-left: 40px;
    font-size: 20px;
    color: $secondary;
    @include mq(md) {
      font-size: 18px;
    }
    @include mq(sm) {
      display: none;
    }
  }
}
</style>